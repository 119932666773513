import classNames from 'classnames'
import * as React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import env from 'src/helpers/env'
import { useScrollReset } from 'src/helpers/hooks'
import { RechooseProvider } from 'src/hooks/rechoose'
import { useFirebaseMessagingTokenManager } from 'src/state/notifications/FirebaseMessagingToken'
import { Alert } from 'src/tailwind/components/Alert'
import Footer from 'src/tailwind/layout/Footer'
import { Header } from 'src/tailwind/layout/Header'
import { Sidebar } from 'src/tailwind/layout/Sidebar'
import NotificationPermissionModal from '../components/NotificationPermissionModal'
import { Rechoose } from './Rechoose'
import RegimeChangeAlert from './RegimeChangeAlert'
import RegimeMessageAlert from './RegimeMessageAlert'
import { SurveyModalStateProvider } from './survey/hooks/survey'
import { Survey } from './survey/views/Survey'

export default function AuthenticatedLayout(): React.ReactElement | null {
  useFirebaseMessagingTokenManager()
  const isProduction = env.APP_ENV === 'PRODUCTION'
  const ref = React.useRef<HTMLDivElement>(null)
  useScrollReset(ref)

  return (
    <SurveyModalStateProvider>
      <RechooseProvider>
        <div
          className={classNames('mx-auto my-0 flex w-full max-w-[2500px] bg-nav', {
            'h-[calc(100vh-35px)]': !isProduction,
          })}
        >
          <Sidebar />
          <div className="z-9 relative w-full overflow-hidden bg-white">
            <Header />
            <div
              className={classNames('h-[calc(100vh-80px)] overflow-y-scroll bg-primaryWhite', {
                'h-[calc(100vh-110px)]': !isProduction,
              })}
            >
              <div
                ref={ref}
                className="mx-auto flex h-full max-w-[2000px] flex-col px-[15px] py-[4%] pb-0 md:px-[30px] xl:p-[40px]"
              >
                <ScrollRestoration />

                <Alert />
                <RegimeMessageAlert />
                <RegimeChangeAlert />

                <Rechoose />

                <Survey />
                <NotificationPermissionModal />
                <div className="flex min-h-full flex-col justify-between">
                  <Outlet />
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </RechooseProvider>
    </SurveyModalStateProvider>
  )
}
